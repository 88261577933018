import { RenderableBase } from './renderable-base';
import { GlobalService } from '../Services/global.service';
import { ServiceLocator } from '../servicelocator';
import { Logger } from '../Services/logger.service';
import { WisApiService } from '../Services/wisapi.service';

interface LabelId {
  label: string;
  // noDiacriticsLabel: string;
  id: number;
}

export class RenderableSearchtree extends RenderableBase<any> {
  public searchtreeId: string;
  public optionref: string;
  public initial: string;
  public rootref: string;
  public locale: string;
  public multiple: boolean;
  public other_value: number;
  public max_values: number;
  public show_search: string;
  public hide_special_items: boolean;
  public sort: boolean;
  public override layout: string;
  public ignoremissingtranslations: boolean;
  public searchtree: {};
  public flatSearchtree: Array<{}> = [];
  public specialItems: Array<{}>;

  private wisApiService: WisApiService;
  private globals: GlobalService;
  private logger: Logger;
  private flatSearchtreeMap: Map<string, string> = new Map();

  constructor(options: {} = {}) {
    super(options);
    this.searchtreeId = options['searchtree_id'];
    this.multiple = options['multiple'] === 'true' || options['multiple'] === '1';
    // this.other_value = options['other_value'] && Math.trunc(options['other_value']);
    this.hide_special_items = options['hide_special_items'] === 'true' || options['hide_special_items'] === '1';
    this.other_value = options['other_value'];
    this.sort = options['sort'] == 'true';
    this.max_values = options['max_values'] && Math.trunc(options['max_values']);
    this.layout = options['layout'] || 'accordion';
    this.ignoremissingtranslations = options['ignoremissingtranslations'] || false;

    this.wisApiService = ServiceLocator.injector.get(WisApiService);
    this.globals = ServiceLocator.injector.get(GlobalService);
    this.logger = ServiceLocator.injector.get(Logger);
    this.init();
  }

  public override lexicalValue() {
    return this.labelForKey(this.value);
  }

  public init() {
    this.wisApiService.initSearchtree(
      this.searchtreeId,
      this.locale || this.globals.locale,
      this.globals.getDisplayLocale(),
      this.optionref || null,
      this.initial || null,
      this.ignoremissingtranslations,
      !this.locale
    );

    // Subscribe to full list of searchtrees
    this.wisApiService.searchtrees.subscribe(
      (result) => {
        const searchTreeKey = this.wisApiService.getSearchTreeKey(
          this.searchtreeId,
          this.locale || this.globals.locale,
          this.globals.getDisplayLocale(),
          this.optionref || null,
          this.initial,
          this.ignoremissingtranslations,
        );

        this.searchtree = result[searchTreeKey];
        if (this.searchtree) {
          this.flatSearchtree = this.flattenSearchtree(this.searchtree, []);
          this.populateFlatSearchtreeMap(this.flatSearchtree); // Populate the map
          this.valueChanged.next(true);
        }
      },
      (error) => {
        this.logger.error('error fetching searchtree');
      },
    );
  }

  public override convertValue(value) {
    let converted = null;
    if (this.multiple) {
      converted = [];
      if (value) {
        if (Array.isArray(value)) {
          for (const val of value) {
            let label = '';
            let id = val;
            if (val.hasOwnProperty('label')) {
              label = val['label'];
            }
            if (val.hasOwnProperty('id')) {
              id = val['id'];
            }
            converted.push({ id, label });
          }
        } else {
          let label = '';
          let id = value;
          if (value.hasOwnProperty('label')) {
            label = value['label'];
          }
          if (value.hasOwnProperty('id')) {
            id = value['id'];
          }
          converted.push({ id, label });
        }
      }
    } else {
      if (value) {
        converted = value;
      }
    }

    return converted;
  }

  /*
   * make a simple array of all items in the tree.
   */
  public flattenSearchtree(
    st: {},
    result: LabelId[],
    leavesOnly = true,
    parentName = '',
  ): LabelId[] {
    for (const child of st['children']) {
      if (child['children'].length > 0) {
        const parentLbl =
          child['translations'][this.globals.getDisplayLocale()] || child['translations']['MASTER'];
        this.flattenSearchtree(child, result, leavesOnly, parentLbl);
      }
      if (!leavesOnly || child.leaf) {
        let label =
          child['translations'][this.globals.getDisplayLocale()] || child['translations']['MASTER'];

        const simpleLabel = '' + label; // force to string
        label = '' + label; // force to string
        if (parentName) {
          label = `${label} (${parentName})`;
        }
        const item = {
          label,
          id: child['id'],
          // noDiacriticsLabel: this.diacriticsPipe.transform(label),
        };

        // check if item is already in result array. We compare the 'id'
        const found = result.find((el) => {
          return el.id === item.id;
        });
        if (!found) {
          result.push(item);
        } else {
          // item is present in multiple subtrees (more than one parent)
          // so we don't use the parentname in the label
          item['label'] = simpleLabel;
        }
      }
    }
    return result;
  }

  public labelForKey(key: number): string {
    return this.flatSearchtreeMap.get(String(key)) || '';
  }

  private populateFlatSearchtreeMap(flatSearchtree: Array<{}>): void {
    this.flatSearchtreeMap.clear();
    for (const item of flatSearchtree) {
      this.flatSearchtreeMap.set(String(item['id']), item['label']);
    }
  }

}

<div *ngIf="!renderable.hidden">
  <div class="spinner" *ngIf="!searchtree">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
    <span class="sr-only">Loading...</span>
  </div>

  <app-ws-constraint-alert [renderable]="renderable" [form]="form"></app-ws-constraint-alert>

  <div [formGroup]="form" role="alert" *ngIf="form && searchtree">
    <ng-template #rt let-r="result" let-t="term">
      {{ r.label }}
    </ng-template>

    <ng-container *ngIf="renderable.multiple">
      <button
        class="btn btn-ws mb-1 me-1"
        type="button"
        [disabled]="renderable.readonly"
        (click)="removeValue(idx, value)"
        [ngClass]="{ is_other: isOtherValue(value) }"
        *ngFor="let value of form.controls[renderable.id]?.value; let idx = index; trackBy: trackByOptions"
      >
        <fa-icon class="align-text-bottom me-1" [icon]="['fas', 'trash-alt']"></fa-icon>
        <span class="align-middle">{{ labelForValue(value) }}</span>
      </button>

      <button
        class="btn btn-ws mb-1 me-1"
        type="button"
        *ngIf="widgetShouldShow() && !toggleWidget"
        (click)="toggleWidget = !toggleWidget"
      >
        <fa-icon class="align-text-bottom" [icon]="['fas', 'plus']"></fa-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="!renderable.multiple">
      <button
        class="btn btn-ws"
        type="button"
        [disabled]="renderable.readonly"
        (click)="onResetClick($event)"
        *ngIf="form.controls[renderable.id]?.value"
      >
        <fa-icon class="align-text-bottom me-1" [icon]="['fas', 'edit']"></fa-icon>
        <span class="align-middle">{{ labelForKey(form.controls[renderable.id]?.value) }}</span>
      </button>
    </ng-container>

    <input
      type="text"
      #other
      class="form-control"
      *ngIf="showOther"
      placeholder="{{ 'Other' | wsTrans: 'wsapp_multi_select_other_option' | async }}"
      (focusout)="setOther(other.value, $event)"
    />

    <div
      class="input-group"
      *ngIf="toggleWidget && !showOther && widgetShouldShow() && renderable.show_search != 'false'"
    >
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </span>

      <input
        type="text"
        class="form-control"
        placeholder="{{ 'Search' | wsTrans: 'Search' | async }}"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        (selectItem)="selectedItem($event)"
        [ngbTypeahead]="search"
      />

      <input type="hidden" [formControlName]="renderable.id" />
    </div>

    <ul
      class="st-accordion"
      *ngIf="
        toggleWidget &&
        widgetShouldShow() &&
        !showOther &&
        (renderable.show_search == 'false' || renderable.layout == 'accordion')
      "
    >
      <searchtree-item
        (notify)="onNotify($event)"
        [level]="1"
        [form]="form"
        [renderable]="renderable"
        [specialItems]="specialItems"
        [children]="searchtree['children']"
      ></searchtree-item>
    </ul>
    
    <ul class="st-accordion" *ngIf="toggleWidget && !showOther && widgetShouldShow() && !renderable.hide_special_items">
      <searchtree-item
        (notify)="onNotify($event)"
        [level]="1"
        [form]="form"
        [renderable]="renderable"
        [children]="specialItems"
      ></searchtree-item>
    </ul>
  </div>
</div>

<ng-container *ngIf="form">
  <div
    class="d-flex container-fluid p-4 m-0 rounded shadow"
    [id]="renderable.id"
    [ngClass]="renderable.extra_classes + ' ' + renderable?.orientation == 'h' ? 'flex-row row horizontal' : 'flex-column'"
    >

    <div
      *ngFor="let sub of renderables; trackBy: trackBySubrenderables"
      [ngClass]="renderable?.orientation == 'h' ? 'col-sm' : ''"
    >
      <app-df-renderable
        *ngIf="sub | wsIsRelevant | async"
        [parentCardGroup]="parentCardGroup"
        [renderable]="sub"
        [form]="form"
      ></app-df-renderable>
    </div>
  </div>
</ng-container>
